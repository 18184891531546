<!--
Copyright © MindMaze Holding SA 2020.  All Rights Reserved.
Unauthorized copying of this file, via any medium is strictly prohibited.
CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
and/or distributed without the express prior written permission of MindMaze Holding SA.
-->

<template>
    <div>
        <b-breadcrumb>
            <b-breadcrumb-item
                :to="{ name: 'intentoDashboard' }"
                v-if="isIntentoUser"
            >
                <b-icon
                    icon="house-fill"
                    scale="1.25"
                    shift-v="1.25"
                    aria-hidden="true"
                ></b-icon>
                Dashboard
            </b-breadcrumb-item>
            <b-breadcrumb-item
                :to="{ name: 'customerDashboard' }"
                v-if="isIntentoSales"
            >
                Customer Management
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'siteLicenses' }">
                {{ customer.company }}
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'license' }">
                License #{{ licenseId }}
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'stimulationSession' }">
                Stimulation Session #{{ stimulationSession.id }}
            </b-breadcrumb-item>
        </b-breadcrumb>

        <b-container fluid>
            <b-row>
                <b-col>
                    <b-card>
                        <b-card-title> Stimulation Session </b-card-title>

                        <b-card-body>
                            <div>
                                <label>
                                    <strong>Exercise:</strong>
                                    {{ formatExerciseName(stimulationSession.exerciseName) }}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <strong>Start date:</strong>
                                    {{
                                        toLocaleDateTime(
                                            stimulationSession.startDatetime
                                        )
                                    }}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <strong>End date:</strong>
                                    {{
                                        toLocaleDateTime(
                                            stimulationSession.endDatetime
                                        )
                                    }}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <strong>Repetitions:</strong>
                                    {{ stimulationSession.repetitions }}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <strong>Stimulation time:</strong>
                                    {{
                                        getActiveStimulationTimeString(
                                            stimulationSession.activeSeconds
                                        )
                                    }}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <strong>Therapist ID:</strong>
                                    {{ stimulationSession.therapistId }}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <strong>Patient ID:</strong>
                                    {{ stimulationSession.patientId }}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <strong>Tablet ID:</strong>
                                    {{ stimulationSession.tabletDeviceId }}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <strong>Intento Pro device ID:</strong>
                                    {{ stimulationSession.intentoProDeviceId }}
                                </label>
                            </div>
                        </b-card-body>
                    </b-card>
                </b-col>

                                <b-col>
                    <b-card v-if="stimulationSession.configuration !== undefined">
                        <b-card-title> Stimulation Configuration </b-card-title>
                        <b-card-body>
                            <div>
                                <label>
                                    <strong>Start date:</strong>
                                    {{
                                        toLocaleDateTime(
                                            stimulationSession.configuration
                                                .startDatetime
                                        )
                                    }}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <strong>End date:</strong>
                                    {{
                                        toLocaleDateTime(
                                            stimulationSession.configuration
                                                .endDatetime
                                        )
                                    }}
                                </label>
                            </div>
                            <div>
                                <label>
                                    <strong>Stimulation time:</strong>
                                    {{
                                        getActiveStimulationTimeString(
                                            stimulationSession.configuration
                                                .activeSeconds
                                        )
                                    }}
                                </label>
                            </div>

                            <br />

                            <b-card>
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <td>Channel</td>
                                            <td>Intensity [mA]</td>
                                            <td>Pulse Width [uS]</td>
                                            <td>Frequency [Hz]</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="parameter in stimulationSession
                                                .configuration
                                                .configurationParameters"
                                            :key="parameter.channel"
                                        >
                                            <td>
                                                {{ parameter.channel }}
                                            </td>
                                            <td>
                                                {{ parameter.intensity }}
                                            </td>
                                            <td>
                                                {{ parameter.pulseWidth }}
                                            </td>
                                            <td>
                                                {{ parameter.frequency }}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </b-card>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col>
                    <b-card v-if="stimulationIntervals.length > 0">
                        <b-row>
                            <b-col>
                                <h4>Intervals</h4>
                                <b-table
                                    striped
                                    hover
                                    selectable
                                    select-mode="single"
                                    :items="stimulationIntervals"
                                    :fields="stimulationIntervalsTableFields"
                                    @row-selected="onIntervalRowSelected"
                                />
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <h4>Stimulation Program</h4>

                                <LineChart
                                    ref="program_chart"
                                    :chartData="programChannels"
                                    :chartOptions="programChannelsChartOptions"
                                >
                                </LineChart>
                            </b-col>
                            <b-col>
                                <h4>Dynamics</h4>
                                <b-table
                                    striped
                                    :items="selectedStimulationDynamics"
                                    :fields="stimulationDynamicsTableFields"
                                />
                            </b-col>
                        </b-row>
                    </b-card>

                    <b-card v-if="stimulationIntervals.length == 0">
                        <b-row>
                            <b-col>
                                No stimulation dynamics data available.
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>

        <br />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
    formatDateTimeToLocaleString,
    formatExerciseName,
    formatSeconds,
} from "@/components/utils.js";
import LineChart from "@/components/LineChart";
import ApiService from "@/services/api.service";

export default {
    name: "StimulationSession",
    components: { LineChart },
    props: {
        stimulationSessionId: {
            type: [String, Number],
            required: true,
        },
        licenseId: {
            type: [String, Number],
            required: true,
        },
        customerId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            customer: {},
            stimulationSession: {
                configuration: {
                    startDatetime: 0,
                    endDateTime: 0,
                },
            },
            stimulationIntervals: {},
            selectedInterval: [],
            selectedStimulationEvents: [],
            selectedStimulationDynamics: [],
            stimulationIntervalsTableFields: [
                "id",
                "startDatetime",
                "endDatetime",
                "startingRepetition",
            ],
            stimulationEventTableFields: [
                "channel",
                "timeStep",
                "intensity",
                "frequency",
                "pulseWidth",
            ],
            stimulationDynamicsTableFields: ["timeStep", "time"],
            programChannels: [
                {
                    datasets: [
                        {
                            data: [],
                        },
                    ],
                },
            ],
            programChannelsChartOptions: {
                responsive: true,
                tooltips: {
                    mode: "index",
                    intersect: false,
                },
                hover: {
                    mode: "nearest",
                    intersect: true,
                },
            },
        };
    },
    async created() {
        this.refreshStimulationSession();
        this.getCustomerData();
    },
    computed: {
        ...mapGetters("auth", ["isIntentoSales", "isIntentoUser"]),
    },
    methods: {
        async refreshStimulationSession() {
            this.loading = true;
            this.stimulationSession = await ApiService.getStimulationSession(
                this.stimulationSessionId
            );
            this.stimulationIntervals = await ApiService.getStimulationSessionIntervals(
                this.stimulationSessionId
            );

            this.onIntervalRowSelected(this.stimulationIntervals);

            this.loading = false;
        },
        async getCustomerData() {
            this.customer = await ApiService.getCustomerAccount(
                this.customerId
            );
        },
        onIntervalRowSelected(items) {
            if (items[0] == null) {
                return;
            }
            this.selectedInterval = items[0];
            this.selectedStimulationDynamics = this.selectedInterval.stimulationDynamics;

            for (var i = 0; i < this.selectedStimulationDynamics.length; i++) {
                if (i == 0) {
                    this.selectedStimulationDynamics[i]["time"] = 0;
                } else {
                    this.selectedStimulationDynamics[i]["time"] =
                        this.selectedStimulationDynamics[i]["timestamp"] -
                        this.selectedStimulationDynamics[0]["timestamp"] +
                        " ms";
                }
            }

            var TIMESTEP_START = 0;
            var TIMESTEP_END = 1000;

            var datasets = [];
            var colors = ["#FFFF00", "#000000", "#FF0000", "#0000FF"];

            for (i = 0; i < this.selectedInterval.channels.length; i++) {
                // Get channel timesteps & intensity
                var values = [{ x: TIMESTEP_START, y: 0 }];

                for ( let events of this.selectedInterval.channels[i].events) {
                    values.push({
                        x: events.timeStep,
                        y: events.intensity,
                    });
                }

                values.push({ x: TIMESTEP_END, y: 0 });

                datasets.push({
                    label: "Channel " + i,
                    data: values,
                    borderColor: colors[i],
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    fill: false,
                    lineTension: 0,
                });
            }

            var xLabels = [];
            for (i = TIMESTEP_START; i <= TIMESTEP_END; i += 1) {
                xLabels.push(i);
            }
            this.programChannels = {
                labels: xLabels,
                datasets: datasets,
            };
        },
        toLocaleDateTime(d) {
            return formatDateTimeToLocaleString(d);
        },
        formatExerciseName(s) {
            return formatExerciseName(s);
        },
        getActiveStimulationTimeString(seconds) {
            return formatSeconds(seconds);
        },
    },
};
</script>

