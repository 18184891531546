<!--
Copyright © MindMaze Holding SA 2020.  All Rights Reserved.
Unauthorized copying of this file, via any medium is strictly prohibited.
CONFIDENTIAL: This software is proprietary and confidential. It cannot be copied
and/or distributed without the express prior written permission of MindMaze Holding SA.
-->

<script>
import { Line } from "vue-chartjs";

export default {
    extends: Line,
    props: ["chartData", "options"],
    mounted() {
        this.update();
    },
    computed: {
        computedChartData: function () {
            return this.chartData;
        },
    },
    methods: {
        update() {
            if (this.$data._chart) {
                this.$data._chart.destroy();
            }
            this.renderChart(this.computedChartData, this.options);
        },
    },
    watch: {
        chartData() {
            this.update();
        },
    },
};
</script>

<style>
</style>